import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function BronzePlaque() {
  const data = useStaticQuery(graphql`
    query BronzePlaqueQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "bronze" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Bronze Plaque"
      title="Bronze Plaque Gallery | Sheboygan Monument &amp; Stone Works"
      description="Bronze plaques that we have made for customers."
      urlPath="bronze-plaque"
    />
  );
}
